$(document).ready(function () {
  /* =====  MOBILE MENU TOGGLE  ====== */
  $(".offCanvasToggler").click(function (event) {
    event.preventDefault();
    $(".off-canvas-nav").toggleClass("active");
    $(".fullscreen-overlay").toggleClass("active");
    $("html, body").toggleClass("no-scrolling");
  });

  /*======  INITS  ======*/

  /*----------  BOOTSTRAP SELECT  ----------*/

  $('.selectpicker').selectpicker({ language: 'nl-NL' });

  /*======  SLIDERS  ======*/

  /*----------  THUMBNAIL SLIDER  ----------*/

  $(".thumbnail-slider").slick({
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    responsive: [
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 575.98,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });

  // ==========[ DATE PICKER ]==========
  $.fn.datepicker.languages['nl-NL'] = {
    format: 'dd-mm-yyyy',
    days: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
    daysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    daysMin: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    weekStart: 1,
    months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
  };
  $('.datepicker').datepicker({ language: "nl-NL" });

  // ==========[ RADIO BUTTONS HANDLERS ]==========
  $('input[type=radio][name=socio_position]').change(function () {
    $('.socio_position_school_info').toggleClass("d-none");
    $('.socio_position_work_info').toggleClass("d-none");
  });

  $('input[type=radio][name=family_situation]').change(function () {
    if ($(this).val() == "kerngezin" || $(this).val() == "co-ouderschap" || $(this).val() == "nieuw-samengesteld-gezin" || $(this).val() == "pleeggezin") {
      $('#person_1').removeClass('d-none');
      $('#person_2').removeClass('d-none');
      $('.sterre').removeClass('d-none');
      $('#other_family_situation_label').addClass("d-none");
      $('#other_family_situation').addClass("d-none");
    } else if ($(this).val() == "een-ouder-gezin") {
      $('#person_1').removeClass('d-none');
      $('#person_2').addClass('d-none');
      $('#other_family_situation_label').addClass("d-none");
      $('#other_family_situation').addClass("d-none");
    } else if ($(this).val() == "andere") {
      $('#person_1').removeClass('d-none');
      $('#person_2').removeClass('d-none');
      $('.sterre').addClass('d-none');
      $('#other_family_situation_label').removeClass("d-none");
      $('#other_family_situation').removeClass("d-none");
    }
  });

  $('input[type=radio][name=adres_pick_1]').change(function () {
    if ($(this).val() == "zelfde") {
      $('.adres_1_field').addClass("d-none");
    } else if ($(this).val() == "afwijkend") {
      $('.adres_1_field').removeClass("d-none");
    }
  });

  $('input[type=radio][name=fact_address]').change(function () {
    if ($(this).val() == "zelfde") {
      $('.fac_address').addClass("d-none");
    } else if ($(this).val() == "afwijkend") {
      $('.fac_address').removeClass("d-none");
    }
  });

  $('input[type=radio][name=adres_pick_2]').change(function () {
    if ($(this).val() == "zelfde") {
      $('.adres_2_field').addClass("d-none");
    } else if ($(this).val() == "afwijkend") {
      $('.adres_2_field').removeClass("d-none");
    }
  });

  $('#adult_check').change(function () {
    if ($(this).is(":checked")) {
      $('#family_situation_wrapper').addClass("d-none");
      $('#person_1').addClass("d-none");
      $('#person_2').addClass("d-none");
    } else {
      $('#family_situation_wrapper').removeClass("d-none");
      let family_situation = $('input[type=radio][name=family_situation]:checked').val();
      if (family_situation == "kerngezin" || family_situation == "co-ouderschap" || family_situation == "nieuw-samengesteld-gezin" || family_situation == "pleeggezin") {
        $('#person_1').removeClass('d-none');
        $('#person_2').removeClass('d-none');
        $('.sterre').removeClass('d-none');
        $('#other_family_situation_label').addClass("d-none");
        $('#other_family_situation').addClass("d-none");
      } else if (family_situation == "een-ouder-gezin") {
        $('#person_1').removeClass('d-none');
        $('#person_2').addClass('d-none');
        $('#other_family_situation_label').addClass("d-none");
        $('#other_family_situation').addClass("d-none");
      } else if (family_situation == "andere") {
        $('#person_1').removeClass('d-none');
        $('#person_2').removeClass('d-none');
        $('.sterre').addClass('d-none');
        $('#other_family_situation_label').removeClass("d-none");
        $('#other_family_situation').removeClass("d-none");
      }
    }
  });

  // ==========[ APPLICATION FORM SUBMIT ]==========
  $("#application_continue").click(function (event) {
    let form = $("#application_form");
    let form_data = new FormData(form[0]);
    let loader = $('.loader');
    loader.removeClass("d-none");
    form_data.append("js_url", "js_set");
    $.ajax({
      url: form.attr("action"),
      data: form_data,
      enctype: "multipart/form-data",
      dataType: "json",
      type: "post",
      processData: false,
      contentType: false,
      success: function (data) {
        if (data.success) {
          // ==========[ REDIRECT TO NEXT STEP ]==========
          if (data.stay) {
            let success_wrapper = $(".errors-wrapper");
            success_wrapper.removeClass("d-none");
            success_wrapper.empty();

            $(data.success_messages).each(function (i, val) {
              let success = '<div class="alert alert-success" role="alert">';
              success += val;
              success += '</div>'
              success_wrapper.append(success);
            });
          } else {
            if (data.redirect_addon) {
              window.location.href = form.attr("data-next") + data.redirect_addon;
            } else {
              window.location.href = form.attr("data-next");
            }
          }
        } else {
          // ==========[ ADD ERROR MESSAGES ]==========
          let errors_wrapper = $('.errors-wrapper');
          errors_wrapper.removeClass("d-none");
          errors_wrapper.empty();
          $(data.errors_messages).each(function (i, val) {
            let error = '<div class="alert alert-danger" role="alert">';
            error += val;
            error += '</div>'
            errors_wrapper.append(error);
          });

          // ==========[ REMOVE PREVIOUS ERRORS ]==========
          $(form).find('.error').each(function () {
            $(this).removeClass('error');
          });

          // ==========[ ADD ERROR CLASS TO FIELDS ]==========
          $(data.errors_fields).each(function (i, val) {
            $('#' + val + '_wrapper').addClass('error');
          });

          // ==========[ SCROLL TO FORM TOP ]==========
          $([document.documentElement, document.body]).animate({
            scrollTop: $("#form-block").offset().top
          }, 2000);
          loader.addClass("d-none");
        }
      }
    });
  });

});
